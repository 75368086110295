import React, { useState } from 'react';
import Close from '../assets/close.svg';

const PortfolioItem = ({img, title, details, images}) => {
    const [modal, setModal] = useState(false);
    const toggleModal = () =>{
        setModal(!modal);
    };

    return (
        <div className="portfolio__item">
            <img src={img} alt="" className="portfolio__img" />

            <div className="portfolio__hover" onClick={toggleModal}>
                <h3 className="portfolio__title">{title}</h3>
            </div>

            {modal && (
                <div className="portfolio__modal">
                    <div className="portfolio__modal-content">
                        <img src={Close} alt="" className="modal__close" onClick={toggleModal} />
                        <h3 className="modal__title">{title}</h3>
                        <ul className="modal__list grid">
                            {details.map(({icon, title, desc, descs, site, icons}, index)=>{
                                return(
                                    <li className='modal__item' key={index}>
                                        <span className='item__icon'>{icon}</span>
                                        <span className='item__icons'><a href={descs}>{icons}</a></span>

                                        <div>
                                            <span className='item__title'>{title}</span>
                                            <span className="item__details"> {desc}</span>
                                            <span className="item__details">{site}</span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className="color" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" className="color" aria-label="Slide 3"></button>
                            </div>
               
                            <div className="carousel-inner" >
                                {images.map(({img1}, index)=>{
                                    return(
                                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                            <img src={img1} className="d-block w-100 modal__img" alt="..." />
                                        </div> 
                                    );
                                })}
                            </div>
                                                       
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>

                    </div>
                </div>
            )}
        </div>
    )
}

export default PortfolioItem