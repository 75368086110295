import React from 'react';
import { personalInfo } from '../data';

const Info = () => {
  return (
    <>
    {personalInfo.map(({title, description, descriptiona}, index)=>{
        return(
            <li className="info__item" key={index}>
                <span className="info__title">{title}</span>
                <span className="info__description">{description}</span>
                <span className="info__description">< a href={descriptiona} >{descriptiona}</a></span>

            </li>
        )
    })}
    </>
  )
}

export default Info