import React, { useState } from 'react';
import Close from '../assets/close.svg';

const CertificatItem = ({img, title, description, date}) => {


    return (
        <div className="portfolio__item">
            <div className="col">
                <div className="card">
                    <img src={img} className="card-img-top portfolio__img" alt="eeeeeeeee" />
                    <div className="portfolio__hover" >
                        <h3 className="card-title portfolio__title">{title}</h3>
                    </div>
                    <div className="card-body" >
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{description}</p>
                    </div>
                    <div className="card-footer">
                        <small className="text-muted">{date}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CertificatItem