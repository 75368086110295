import {
  FaHome,
  FaUser,
  FaFolderOpen,
  FaEnvelopeOpen,
  FaBriefcase,
  FaGraduationCap,
  FaCode,
} from 'react-icons/fa';

import { FiFileText, FiExternalLink } from 'react-icons/fi';

// Portfolio:
import Ts0 from './assets/ts/ts0.JPG';
import Ts1 from './assets/ts/ts1.JPG';
import Ts2 from './assets/ts/ts2.JPG';

import Fs0 from './assets/fs/fs0.JPG';
import Fs1 from './assets/fs/fs1.JPG';
import Fs2 from './assets/fs/fs2.JPG';
import Fs3 from './assets/fs/fs3.JPG';


import Tr0 from './assets/tr/tr0.JPG';
import Tr1 from './assets/tr/tr1.JPG';
import Tr2 from './assets/tr/tr2.JPG';
import Tr3 from './assets/tr/tr3.JPG';

import Pf0 from './assets/pf/pf0.JPG';
import Pf1 from './assets/pf/pf1.JPG';
import Pf2 from './assets/pf/pf2.JPG';
import Pf3 from './assets/pf/pf3.JPG';

// Certificats:
import Git from './assets/certif/git.JPG';
import Mos from './assets/certif/mos.JPG';
import Py from './assets/certif/opp phyton.JPG';
import Php from './assets/certif/php.JPG';
import React from './assets/certif/react.JPG';
import Sql from './assets/certif/sql.JPG';




import Theme1 from './assets/purple.png';
import Theme2 from './assets/red.png';
import Theme3 from './assets/blueviolet.png';
import Theme4 from './assets/blue.png';
import Theme5 from './assets/goldenrod.png';
import Theme6 from './assets/magenta.png';
import Theme7 from './assets/yellowgreen.png';
import Theme8 from './assets/orange.png';
import Theme9 from './assets/green.png';
import Theme10 from './assets/yellow.png';
import CertificatItem from './components/CertificatItem';

export const links = [
  {
    id: 1,
    name: 'Home',
    icon: <FaHome className='nav__icon' />,
    path: '/',
  },

  {
    id: 2,
    name: 'About',
    icon: <FaUser className='nav__icon' />,
    path: '/about',
  },

  {
    id: 3,
    name: 'Portfolio',
    icon: <FaFolderOpen className='nav__icon' />,
    path: '/portfolio',
  },


  {
    id: 5,
    name: 'Contact',
    icon: <FaEnvelopeOpen className='nav__icon' />,
    path: '/contact',
  },
];

export const personalInfo = [
  {
    id: 1,
    title: 'First Name : ',
    description: 'Noureddine',
  },

  {
    id: 2,
    title: 'Last Name : ',
    description: 'Laaroussi',
  },

  {
    id: 3,
    title: 'Age : ',
    description: '31 Years',
  },

  {
    id: 4,
    title: 'Nationality : ',
    description: 'Morocco',
  },

  {
    id: 5,
    title: 'Freelance : ',
    description: 'Available',
  },

  {
    id: 6,
    title: 'Address : ',
    description: 'Massira 01, Témara',
  },

  {
    id: 7,
    title: 'Phone : ',
    description: '+212 613 208 919',
  },

  {
    id: 8,
    title: 'Email : ',
    description: 'nouredinelaaroussi9@gmail.com',
  },

  {
    id: 9,
    title: 'Linkedin : ',
    descriptiona: 'https://www.linkedin.com/in/noureddine-laaroussi-2a8934157/',
  },

  {
    id: 10,
    title: 'Langages : ',
    description: 'Arabe, French, English',
  },
];

export const stats = [
  {
    id: 1,
    no: '1+',
    title: 'Years of <br /> Experience',
  },

  {
    id: 2,
    no: '4+',
    title: 'Completed <br /> Projects',
  },

  {
    id: 3,
    no: '10+',
    title: 'Happy <br /> Customers',
  },

  {
    id: 4,
    no: '5+',
    title: ' Awards <br /> Won',
  },
];

export const resume = [
  {
    id: 1,
    category: 'experience',
    icon: <FaBriefcase />,
    year: 'Aug 1, 2023 - Aug 31, 2023',
    title: 'LISACOM <span> TEMARA </span> ',
    desc: 'Internship as a Wordpress Web Developer',
  },

  {
    id: 2,
    category: 'experience',
    icon: <FaBriefcase />,
    year: 'May 2018 - Nov 2018',
    title: 'INTERNATIONAL UNIVERSITY <span> RABAT </span>',
    desc: 'Internship as a Physics Researcher at the School of Engineering aerospace',
  },

  {
    id: 3,
    category: 'experience',
    icon: <FaBriefcase />,
    year: 'Jan 2016 - June 2016',
    title: 'FACULTY OF SCIENCES <span> RABAT </span>',
    desc: 'Internship as a Physics Researcher at the Laboratory of Condensed Matter and Interdisciplinary Sciences',
  },

  {
    id: 4,
    category: 'experience',
    icon: <FaBriefcase />,
    year: 'Sept 2014 - June 2016',
    title: 'SCHOOL IN THE PRESENT <span> RABAT </span>',
    desc: 'Work as animator and organizer of the carnival annual',
  },

  {
    id: 5,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'July 2023 - PRESENT',
    title: 'JobInTech | National School of Arts and Crafts <span> RABAT </span>',
    desc: "Full-stack PHP Development Training",
  },

  {
    id: 6,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'Sept 2021 - Aug 2023',
    title: 'Specialized Institute of Applied Technology  <span> TEMARA </span>',
    desc: 'Specialized Development Technician Diploma Digital Option Web Full – Stack',
  },

  {
    id: 7,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'Dec 2018 - Dec 2021',
    title: 'FACULTY OF SCIENCES  <span> RABAT </span>',
    desc: 'Preparation of the thesis at the Laboratoire de la Matière Condensed and Interdisciplinary Sciences (LAMCSCI)',
  },

  {
    id: 8,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'Sep 2016 - Nov 2018',
    title: 'FACULTY OF SCIENCES  <span> RABAT </span>',
    desc: 'Master’s Degree Option Physics – Computer Science',
  },

  {
    id: 9,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'Sep 2015 - June 2016',
    title: 'FACULTY OF SCIENCES  <span> RABAT </span>',
    desc: 'Fundamental licence Degree in Science Physical Matter',
  },

  {
    id: 10,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'Sep 2011 - June 2014',
    title: 'FACULTY OF SCIENCES  <span> RABAT </span>',
    desc: 'DEUG diploma in Science Physical Matter',
  },

  {
    id: 11,
    category: 'education',
    icon: <FaGraduationCap />,
    year: 'Sep 2010 - June 2011',
    title: 'MOULAY ALI CHERIF HIGH SCHOOL  <span> TEMARA </span>',
    desc: 'Bachelor of Physical Sciences',
  },

  {
    id: 12,
    img: React,
    category: 'certificats',
    title: 'React + Redux',
    description: 'Sololearn',
    date: 'On July 2023',
  },

  {
    id: 13,
    img: Py,
    category: 'certificats',
    title: 'OOPs in Phyton',
    description: 'Great Learning Academy',
    date: 'On July 2023',
  },

  {
    id: 14,
    img: Sql,
    category: 'certificats',
    title: 'Advanced SQL',
    description: 'Great Learning Academy',
    date: 'On July 2023',
  },

  {
    id: 15,
    img: Php,
    category: 'certificats',
    title: 'PHP',
    description: 'Sololearn',
    date: 'On May 2023',
  },

  {
    id: 16,
    img: Mos,
    category: 'certificats',
    title: 'Excel 2019 Associate',
    description: 'Microsoft Office Specialist',
    date: 'On March 2023',
  },

  {
    id: 17,
    img: Git,
    category: 'certificats',
    title: 'Manage code with Git',
    description: 'OpenClassRomms',
    date: 'On January 2023',
  },
];

export const skills = [
  {
    id: 1,
    title: 'Html',
    percentage: '95',
  },

  {
    id: 2,
    title: 'Css',
    percentage: '80',
  },
  
  {
    id: 13,
    title: 'Bootstrap',
    percentage: '90',
  },

  {
    id: 3,
    title: 'Javascript',
    percentage: '70',
  },

  {
    id: 4,
    title: 'Php',
    percentage: '85',
  },

  {
    id: 7,
    title: 'Laravel',
    percentage: '65',
  },

  {
    id: 5,
    title: 'Wordpress',
    percentage: '85',
  },

  {
    id: 8,
    title: 'React JS',
    percentage: '65',
  },

  {
    id: 9,
    title: 'MySql',
    percentage: '90',
  },

  {
    id: 10,
    title: 'Git',
    percentage: '80',
  },

  {
    id: 11,
    title: 'Jira',
    percentage: '50',
  },

  {
    id: 12,
    title: 'MongoDB',
    percentage: '60',
  },


];

export const portfolio = [
  {
    id: 1,
    img: Ts0,
    title: 'PHP',
    details: [
      {
        icon: <FiFileText />,
        title: 'Project : ',
        desc: 'E-commerce',
      },

      {
        icon: <FaCode />,
        title: 'Language : ',
        desc: 'Html, Css, Bootstrap, JS, Php, MySQL',
      },
      {
        icons: <FiExternalLink />,
        title: 'Preview : ',
        descs: 'https://temaraclothes.000webhostapp.com',
        site: 'www.temaraclothes.com',

      },
    ],
    images:[
      {
        id:9,
        img1: Ts0,
      },
      {
        id:10,
        img1: Ts1,
      },
      {
        id:11,
        img1: Ts2,
      }
    ]
  },

  {
    id: 2,
    img: Pf0,
    title: 'React Js',
    details: [
      {
        icon: <FiFileText />,
        title: 'Project : ',
        desc: 'Portfolio',
      },

      {
        icon: <FaCode />,
        title: 'Language : ',
        desc: 'React Js, Bootstrap, JavaScript',
      },
      {
        icons: <FiExternalLink />,
        title: 'Preview : ',
        descs: 'https://noureddinelaaroussi.pages.dev/',
        site: 'www.N-laaroussi.com'
      },
    ],
    images:[
      {
        id:20,
        img1: Pf1,
      },
      {
        id:21,
        img1: Pf2,
      },
      {
        id:22,
        img1: Pf3,
      },
    ]
  },

  {
    id: 3,
    img: Fs0,
    title: 'WordPress',
    details: [
      {
        icon: <FiFileText />,
        title: 'Project : ',
        desc: 'Web Site Shopping',
      },

      {
        icon: <FaCode />,
        title: 'Language : ',
        desc: 'Wordpress',
      },
      {
        icons: <FiExternalLink />,
        title: 'Preview : ',
        descs: 'https://dev-temarafashion.pantheonsite.io/#',
        site: 'www.temarafashion.io',
      },
    ],
    images:[
      {
        id:20,
        img1: Fs1,
      },
      {
        id:21,
        img1: Fs2,
      },
      {
        id:22,
        img1: Fs3,
      },
    ]
  },

  {
    id: 4,
    img: Tr0,
    title: 'WordPress',
    details: [
      {
        icon: <FiFileText />,
        title: 'Project : ',
        desc: 'Web Site Travel',
      },

      {
        icon: <FaCode />,
        title: 'Language : ',
        desc: 'Wordpress',
      },
      {
        icons: <FiExternalLink />,
        title: 'Preview : ',
        descs: 'https://guideworldtravel.com/',
        site: 'www.guideworldtravel.com'
      },
    ],
    images:[
      {
        id:20,
        img1: Tr1,
      },
      {
        id:21,
        img1: Tr2,
      },
      {
        id:22,
        img1: Tr3,
      },
    ]
  },
];


export const themes = [
  {
    id: 1,
    img: Theme1,
    color: 'hsl(252, 35%, 51%)',
  },

  {
    id: 2,
    img: Theme2,
    color: 'hsl(4, 93%, 54%)',
  },

  {
    id: 3,
    img: Theme3,
    color: 'hsl(271, 76%, 53%)',
  },

  {
    id: 4,
    img: Theme4,
    color: 'hsl(225, 73%, 57%)',
  },

  {
    id: 5,
    img: Theme5,
    color: 'hsl(43, 74%, 49%)',
  },

  {
    id: 6,
    img: Theme6,
    color: 'hsl(339, 81%, 66%)',
  },

  {
    id: 7,
    img: Theme7,
    color: 'hsl(80, 61%, 50%)',
  },

  {
    id: 8,
    img: Theme8,
    color: 'hsl(19, 96%, 52%)',
  },

  {
    id: 9,
    img: Theme9,
    color: 'hsl(88, 65%, 43%)',
  },

  {
    id: 10,
    img: Theme10,
    color: 'hsl(42, 100%, 50%)',
  },
];
