import React, { useRef } from 'react';
import {
  FaEnvelopeOpen,
  FaPhoneSquareAlt,
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';
import {FiSend} from 'react-icons/fi';
import './contact.css';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5xmqigb', 'template_5bb90u9', form.current, 'CSZeMxLEGqJ0Sr2U9')
      .then((result) => {
          console.log(result.text);
          document.getElementById('alert').innerHTML='<div class="alert alert-info alert-dismissible fade show" role="alert">Your message is send with <b>success</b><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'; 
          setTimeout(function() {document.getElementById('alert').innerHTML='';},5000);

      }, (error) => {
          console.log(error.text);
      });
  };

  const valid = (e)=>{
    let name= document.forms["form"]["to_name"];
    let email= document.forms["form"]["from_name"];
    let my_subject = document.forms["form"]["my_subject"];
    let message = document.forms["form"]["message"];


    if(name.value === '' || email.value === '' || my_subject.value === '' || message.value === ''){
      e.preventDefault();

      name.style.border='1px solid red';
      email.style.border="1px solid red";
      my_subject.style.border="1px solid red";
      message.style.border="1px solid red";

      document.getElementById('alert').innerHTML='<div class="alert alert-danger alert-dismissible fade show" role="alert">Please Fill The <b>Boxs</b><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'; 
      setTimeout(function() {document.getElementById('alert').innerHTML='';},3000);
      return false
    }
      name.value='';
      email.value="";
      my_subject.value="";
      message.value="";
      return true

}


  return (
    <>
    <div id='alert'>
    
    </div>
    <section className="contact section">
      <h2 className="section__title">
        Get In <span>Touch</span>
      </h2>

      <div className="contact__container container grid">
        <div className="contact__data">
          <h3 className="contact__title">Don't be Shy!</h3>

          <p className="contact__description">
            Feel free to get in touch with me. 
            I am always open to discussing new projects, 
            creative ideas or opportunities to be part of your visions.
          </p>

          <div className="contact__info">
            <div className="info__item">
              <FaEnvelopeOpen className='info__icon' />

              <div>
                <span className="info__title">Mail me</span>
                <h4 className="info__desc">nouredinelaaroussi9@gmail.com</h4>
              </div>
            </div>

            <div className="info__item">
              <FaPhoneSquareAlt className='info__icon' />

              <div>
                <span className="info__title">Call me</span>
                <h4 className="info__desc">+212 613 208 919</h4>
              </div>
            </div>
          </div>

          <div className="contact__socials">
            <a href="https://www.facebook.com/nouredine.laaroussi1" className="contact__social-link">
              <FaFacebookF />
            </a>

            <a href="https://www.instagram.com/noureddine_laaroussi/" className="contact__social-link">
              <FaInstagram />
            </a>

            <a href="https://www.linkedin.com/in/noureddine-laaroussi-2a8934157/" className="contact__social-link">
              <FaLinkedin />
            </a>

          </div>
        </div>

        <form className="contact__form" ref={form} onSubmit={sendEmail} name='form'>
          <div className="form__input-group">
            <div className="form__input-div">
              <input type="text" placeholder='Your Name' className="form__control" name="to_name" />
            </div>

            <div className="form__input-div">
              <input type="email" placeholder='Your Email' className="form__control" name="from_name" />
            </div>

            <div className="form__input-div">
              <input type="text" placeholder='Your Subject' className="form__control" name="my_subject" />
            </div>
          </div>

          <div className="form__input-div">
              <textarea placeholder='Your Message' className='form__control textarea' name="message"></textarea>
          </div>

          <button className="button" type="submit" value="Send" onClick={valid}>
            Send Message
            <span className="button__icon contact__button-icon">
              <FiSend />
            </span>
          </button>
        </form>



      </div>
    </section>
    </>
  )
}

export default Contact


