import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Themes from './components/Themes';
import About from './pages/about/About';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Portfolio from './pages/portfolio/Portfolio';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Navbar />
          <Themes />
          <Routes>
            <Route index element={<Home />}/>
            <Route path='/about' element={<About />} />
            <Route path='/portfolio' element={<Portfolio />}/>
            <Route path='/contact' element={<Contact />}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
